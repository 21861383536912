import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Organization } from 'src/app/models/Organization';
import { AdminService } from 'src/app/services/admin.service';
import { ModalComponent } from '../modal/modal.component';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { ProductsListComponent } from '../products-list/products-list.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit{
  loading:boolean = false
  currentPage = 1
  currentPageSize:number = 1
  organizations: Organization[] = []
  constructor(
    private toast:ToastrService,
    private adminService:AdminService,private router:Router) {

  }
  
@ViewChild('modal', { static: false }) modal!: ModalComponent;
@ViewChild('productModal', { static: false }) productModal!: ModalComponent;
@ViewChild('products', { static: false }) products!: ProductsListComponent;
query = new FormControl
curId?:any;
toggleModal(id:any = null) {
  if(this.modal)this.modal.toggleModal();
  if(id) this.curId = id; else this.curId = null
}
  ngOnInit(): void {
    this.bringOrgs(1)
    
    
  }
  updateOrg(pId:any,org:any){
    this.adminService.updateOrganizations(pId,
      {
        "name": org.name,
        "description": org.description,
        "tin": parseInt(org.tin),
        "address": org.address,
        "email": org.email,
        "phone": org.phone,
        "enabled": !org.enabled
      }
    ).subscribe((data:any) => {
      console.log(data)
      org.enabled = !org.enabled
      //if(data)this.adminService.getProducts()
    })
  }
  getMenu(id:any,a:any){
    return [{name:'Edit',action:()=>this.goToOrg(id)},
    {name:'Delete',message:'Are you sure you want to delete this',type:'comfirm',action:()=>{
      this.adminService.deleteOrganizations(id).subscribe({next:(data) => {

        this.toast.success('Organization Deleted', 'SUCESS',{toastClass: "custom-toast-success"})
        this.bringOrgs(this.currentPage);
      },error:()=>{

      }});
    }},
  
    {name:a.enabled?'Unpublish':'Publish',action:()=>{
      this.updateOrg(id,a);
    },
  }]
  }
  round(val:number) {
    return Math.ceil(val)
  }
  bringOrgs(page:number) {
    this.loading = true
    const params = {
      skip: (page-1)*10,
      take: 10,
      q:this.query.value
    }
    this.adminService.getOrganizations(params,this.query.value && this.query.value != "").subscribe({next:(data) => {
      if(data){
        
      }
      this.organizations = data.data
      this.currentPageSize = data.size
      console.log(data)
      this.loading = false
    },error:err => this.loading =false})
  }
  create(){
    this.router.navigate(['/organization/create'])
  }
  goToOrg(orgId: string|number) {
    this.router.navigate(['/config/'+orgId])
  }
 actions = {openProductModal: (orgId: string|number)=>{
  this.bringOrgs(this.currentPage)
  this.curId = orgId
  this.products.bringProducts(orgId)

  this.modal.toggleModal();
  this.productModal.toggleModal();
  },
  toggleModal:() => 
  this.modal.toggleModal()
}

}
