<div class="flex justify-end gap-3 items-center">
    <button [ngClass]="{
        'opacity-0 pointer-events-none':!(current>1)
    }" (click)="onClick(current -1 > 0?current - 1:1)" class=" text-[#576EED]">Prev</button>
    <button
    (click)="onClick(x+1)"
    [ngClass]="{
        '!rounded-full !text-white !bg-[#576EED]':x+1 === current
    }"
     class="h-10 w-10 text-[#656C95] bg-[#272B44] rounded-xl hover:-translate-y-[3px] ease-in transform duration-150" *ngFor="let page of ' '.repeat(size).split(''), let x = index">
        {{x+1}}
    </button>
    
    <button [ngClass]="{
        'opacity-0 pointer-events-none':!(current<size)
    }" (click)="onClick(current +1 <= size?current + 1:size)" class=" text-[#576EED]">Next</button>
</div>