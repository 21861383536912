import { Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, Input, NgModule } from '@angular/core';
import { NgxSliderModule } from 'ngx-slider-v2';


@Component({
  standalone:true,
  imports: [CommonModule,NgxSliderModule],
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  minValue: number = 20;
  maxValue: number = 80;
  @Input() data: any[] = [
    { value: 20, legend: "Very slow" },
    { value: 80, legend: "" },
    { value: 0, legend: "Very High" },
  ];
  
  options: Options = {
    floor: 0, 
    ceil: 100,
    step: 10,
    stepsArray:this.data,
    showTicks: true,
  };
  constructor() { }

  ngOnInit(): void {
    this.options.stepsArray = this.data;
  }
}