import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-org',
  templateUrl: './edit-org.component.html',
  styleUrls: ['./edit-org.component.scss']
})
export class EditOrgComponent implements OnInit {
  id = null;
  constructor(
    private activateRoute: ActivatedRoute,

  ){}
  ngOnInit(): void {
    this.id = this.activateRoute.snapshot.params['id'];
  }
}
