<app-mobile-menu [dark]="true" />
<!--<app-side-bar [orders]="orders" #sidebar/> -->
<div class="bg-yv overflow-hidden justify-center flex z-30 min-h-screen" >
  <div class="fixed hidden float-right p-2 w-full  md:flex items-center top-0 justify-end gap-8 px-[88px] pl-[172px]  max-w-screen-2xl z-10">
    <!-- <app-yieldvestor class="flex-1" [yieldVestorData]="{
      firstText: ' Yield',
      secondText: 'Vestor',
      logo: 'assets/img/icon/logo-alt.svg',
      firstTextClass: 'text-white',
      secondTextClass: 'text-[#2CC784]',
    }" ></app-yieldvestor> -->
    <div class="flex-1 p-4">
      <!--
      <img [routerLink]="'/'" src="assets/img/icon/fulllogo.svg" class="cursor-pointer"  />
      -->
    </div>

    <app-rounded-icon-buttom *ngFor="let item of navMenu" (btnClick)="item.action()" [buttonData]="item" />
    
    <span (click)="logout()" class="flex" >
      <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/person.svg',iconClass:'h-[1.5rem] text-white'}" />
      <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/select_arrow_clear.svg',iconClass:'mr-[1rem]',btnClass:'!bg-transparent w-[2rem] '}" />
    </span>
    
  </div>

  <!-- ------------------------------------- -->
  <!--  SIDE MENU -->
  <!-- ------------------------------------- -->
  <div class="pl-3 pr-3 yieldvestor-sideMenu [98px] h-screen hidden fixed left-0 top-0  md:flex flex-col pt-8 gap-9 items-center z-10" >
        
      <ng-container *ngFor="let item of sideMenu; let i = index" >
          <div *ngIf="item.role.includes(role)" class="cursor-pointer" (click)="onMenuClick(i)"  >
            
            <!--div class="place-items-center" [ngClass]="{item.route == url ? 'sideMenuItenActive' : '', item.route == '/' ? 'mt-5' : ''}"-->
            <div class="place-items-center sideMenuIten" [ngClass]="{'sideMenuItenActive': item.route === url ,'items-center mb-5' : i === 0 }">

              <img *ngIf="i==0" class="m-auto iconMenu svg-image" [ngClass]="item.route == '/' && item.name != 'Dashboard' ? 'iconMenuHome' : item.route == url ? '' : ''" [src]="item.icon" alt=""/>

              <div class="boxIcon flex place-items-center m-auto" *ngIf="i>0" >
                 <img  class="m-auto iconMenu svg-image" [ngClass]="item.route == '/' && item.name != 'Dashboard' ? 'iconMenuHome' : item.route == url ? '' : ''" [src]="item.icon" alt=""/>
              </div>
            
              <div class="text-[12px] font-normal mt-1">
                {{ item.name }}
              </div>

            </div>

          </div>
      </ng-container>

  </div>


  <!-- ------------------------------------- -->
  <!--  Main Area -->
  <!-- ------------------------------------- -->
  <div  class="md:pl-[400px] md:pt-[88px] md:pr-[88px] p-5 w-full max-w-screen-2xl mx-auto bg-yv text-white">
    
      <!-- SECOUND SIDE MENU   -->
      <div class="w-[200px] h-screen hidden fixed text-yv left-[120px] top-0 md:flex flex-col pt-8 gap-16  z-30">
            <div class="w-100">
              <!--div class="accordion-item" *ngIf="isConfig()">
                <button class="text-white text-2xl font-[600]" (click)="toggleAccordion($event)">Organizations</button>
                <div class="accordion-content py-4">
                  <app-button [buttonData]="{classText:(route == 'config'?'!text-yv-primary-100':''), textButton:'Organizations',classButton:'!bg-[#0d132e] w-full py-4 hover:!bg-[#FFFFFF1a]'}" />
                </div>
              </div-->
              
              <!--div class="accordion-item" *ngIf="isConfig()">
                <button class="text-white text-2xl font-[600]" (click)="toggleAccordion($event)">Products</button>
                <div class="accordion-content">
                  ...
                </div>
              </div-->
              


              <!-- Secound side menu -->
              <div class="accordion-item mt-5 box-side-menu" >
                
                <div *ngFor="let items of getSideMenu(); let i2 = index" >
                  <div class="text-white mb-3"> {{ items.title }}</div>
                  <div *ngFor="let item of items.itensMenu; let i = index" [routerLink]="item.route" class="cursor-pointer mt-1 mb-2 bt-menu-notification"  [ngClass]="{'bt-menu-notification-active': (getCurrentMenu())==item.route || (item.sub_route).includes(getCurrentMenu()) }"> {{ item.name}} </div>  
                </div>
              </div>
            
              <!-- Add more sections as needed -->
            </div>
      </div>
      
      <!-- MAIN CONTENT   -->

      <ng-content></ng-content>

      <!-- FOOTER   -->
      <div class="w-1/4 text-[14px] !text-[#B3B3B3]">
        <app-footer />
      </div>

  </div>
  
  <div></div>
</div>

<div class="fixed w-screen top-0 left-0 h-screen bg-repeat pointer-events-none"></div>
