<button class="bg-yv-primary-50 rounded-lg bt-addnew  flex justify-center items-center gap-2 hover:bg-yv-primary-60 focus:bg-yv-primary-60 {{
    buttonData.classButton }}"
  [disabled]="buttonData.disabled"
  [ngClass]="buttonData.disabled ? 'bg-yv-primary-60 opacity-30' : ''"
>
  <img
    class="{{ buttonData.classIcon }} me-1"
    [src]="buttonData.iconUrl"
    alt="icon left"
    *ngIf="buttonData.IconLeft && !buttonData.loading"
  />

  <span
    *ngIf="!buttonData.loading && !buttonData.loading"
    class="text-white spanLable {{ buttonData.classText }}"
    style="font-size: 12px;" >
    {{ buttonData.textButton }}
  </span>

  <img
    class="w-[2rem]"
    src="assets/img/icon/loading.svg"
    alt="loading"
    *ngIf="buttonData.loading"
  />
  <img
    class="ms-1"
    [src]="buttonData.iconRightUrl"
    alt="icon right"
    *ngIf="buttonData.iconRightUrl && !buttonData.loading"
  />
</button>
