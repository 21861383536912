<form class="" [formGroup]="form">
    <div class="grid md:grid-cols-2 gap-8 mb-8">
        <app-input-form [control]="name" [inputData]="{
            
            inputType:'text',label:'Organization Name',name:'name',placeholder:'Organization Name'}"/>
            <app-input-form  [control]="tin" [inputData]="{inputType:'number',label:'Tin',name:'tin', placeholder:'Insert Tin'}"/>
            <app-input-form [control]="address" [inputData]="{inputType:'text',label:'Adress',name:'address',placeholder:'Insert Adress'}"/>
            <app-input-form [control]="email" [inputData]="{inputType:'email',label:'Email',name:'email',placeholder:'Insert Email'}"/>
            <app-input-form [control]="phone" [inputData]="{inputType:'number',label:'Phone',name:'phone',placeholder:'Insert Phone'}"/>
        
            <!-- <app-input-form [control]="description" [inputData]="{
            
            inputClass:form.errors?'shadow-[0px_0px_30px_0px_#978EF54D]':'',
            inputType:'textarea',label:'Description',name:'description',placeholder:'Make A Description For the Organization'}" /> -->
    </div>

    <div class="">
        <label
    class="block text-yv-white-50 font-poppins mb-1 "
    for=""
    >Description</label>
        <app-text-editor #editor [control]="description" />
       
    </div>

    <div class="flex justify-end gap-5 mt-5 mb-5">
        <app-button (click)="saveOrg()" [buttonData]="{loading:saving, classButton:'w-[160px] p-2',textButton:'Save'}"></app-button>
        <app-button *ngIf="actions?.toggleModal" (click)='actions?.toggleModal()' [buttonData]="{classButton:'p-2 !bg-transparent w-[160px] !text-yv-primary-50 border broder-yv-primary-50',textButton:'Cancel'}"></app-button>
    </div>

</form>
