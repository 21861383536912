import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() dropdownData: any;
  isDropdownOpen = false;

  constructor(private el: ElementRef){
    
  }

 

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  handleMenuItemClick(option: string) {
    // Implement your logic for handling menu item clicks here
    console.log(`Clicked on: ${option}`);
  }


  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    // Check if the click event occurred outside the dropdown
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  performAction(action: () => void) {
    if (action) {
      action();
    }
  }

}
