<div>

    <div class="flex justify-between items-center mb-8">
        <div class="font-bold text-xl">Users</div>
        <div class="flex-grow flex justify-end items-end gap-8">
      <app-button (click)="toggleModal()" [buttonData]="{classButton:'p-2',textButton:'Add New'}"></app-button>
    </div>
    </div>
    <div>
        <table class="w-full">
            <thead class="">
                <tr>
                    <td>Name</td>
                    <td class="pl-4">Email</td>
                    <td class="pl-4">Username</td>

        <td>Actions</td>
                </tr>
            </thead>
            <tbody class="">
                <ng-container *ngFor="let q of users">
                <tr><td class="h-2"> </td></tr>
                <tr class="">
                    <td class="p-4 bg-[#FFFFFF1A] rounded-l-xl">{{q.firstName}} {{q.lastName}}</td>
                    <td class="p-4 bg-[#FFFFFF1A] ">{{q.email}}</td>
                    <td class="p-4 bg-[#FFFFFF1A] ">{{q.username}}</td>

        <td class="p-4 bg-[#FFFFFF1A] rounded-r-xl">
            <app-actions [menu]="getMenu(q.id,q)" />
        </td>

                </tr>
            </ng-container>
            </tbody>
        </table>
        <app-loading *ngIf="loading" />

    </div>
</div>
    
<app-modal title="New User" #modal>
    <div class="mb-8">
        <app-input-form [control]="firstName" [inputData]="{inputType:'text',label:'First Name',name:'UserName',placeholder:'User Name'}"/>
    </div>
    <div class="mb-8">
        <app-input-form [control]="lastName
        " [inputData]="{inputType:'text',label:'Last Name',name:'lastName',placeholder:'User Last Name'}"/>
    </div>
    <div class="mb-8">
        <app-input-form [control]="email" [inputData]="{inputType:'email',label:'Email',name:'Email',placeholder:'User Email'}"/>
    </div>
    <div class="mb-8">
        <app-input-form [control]="name" [inputData]="{inputType:'text',label:'Username',name:'UserName',placeholder:' Username'}"/>
    </div>
    
   
   
    <!-- <div class="mb-8">
        <app-input-form [control]="password" 
        [inputData]="{
            inputClass:form.errors?'shadow-[0px_0px_30px_0px_#978EF54D]':'',
            inputType:'number',label:'password',name:'password',placeholder:'Insert password'}"/>
    </div> -->
    
    
        <div class="mt-5 flex justify-between gap-8 items-end" >
            <div class="flex-grow">
         
    </div>
        <app-button (click)="save()" [buttonData]="{loading:saving, classButton:'w-[160px] p-2',textButton:'Save'}"></app-button>
    </div>
</app-modal>