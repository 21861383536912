
<div class="relative" (click)="toggleDropdown()">
    <!-- Trigger button -->
    <!--button class="bg-blue-500 text-white font-semibold py-2 px-4 rounded inline-flex items-center">
      Open Dropdown
      
    </button-->

    <img class="ml-1 " width="14px" src="assets/img/icon/three-dots-vertical.svg" />
  
    <!-- Dropdown menu -->
    <div class="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg" [hidden]="!isDropdownOpen" >
      <ul>
        <li *ngFor="let dropdown of dropdownData">
          <div class="block px-4 py-2 text-gray-800 hover:bg-gray-200"  (click)="performAction(dropdown.action)">
            {{dropdown.name}}
          </div>
        </li>
        <!--li>
          <a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-200"  (click)="handleMenuItemClick('Option 2')" >
            Delete
          </a>
        </li-->
        <!-- Add more menu items as needed -->
      </ul>
    </div>
  </div>
