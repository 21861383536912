<div class=" {{ selectData.selectFormClass }}">
  
  <label
    class="block text-yv-white-50 font-poppins mb-1 {{ selectData.labelClass }}" for="">
    {{ selectData.label }}
  </label>

  <!--select [formControl]="control"
    class="block !w-full text-yv-white-50 bg-yv-primary-70 border border-transparent focus:border-blue-500 rounded-lg font-poppins leading-6 text-base {{
      selectData.selectClass
    }} focus:border-[#978EF5] duration-500
    focus:shadow-[0px_0px_30px_0px_#978EF54D]"
    (change)="onSelectChange()"
    name="cars"
    id="cars"
    style="">
      <option value="">{{default}}</option>
      <option *ngFor="let item of items" [selected]="item.value == defaultValue" value="{{ item.value }}">
        {{ item.name }}
      </option>
  </select-->


  <div class="select-wrapper">
    <select [formControl]="control" 
          class="form-field block w-full  rounded-md  border border-gray-300 {{
            selectData.selectClass
          }}"
          
          (change)="onSelectChange()"
          name="cars"
          id="cars" >

      <!-- Dropdown options here -->
      <option [value]="initValue">{{default}}</option>
        <option *ngFor="let item of items" [selected]="item.value == defaultValue" value="{{ item.value }}">
          {{ item.name }}
        </option>
    </select>
  </div>

</div>
