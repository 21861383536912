import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import Editor from 'ckeditor5/build/ckeditor';
import { EditorConfig } from '@ckeditor/ckeditor5-core';

@Component({

  standalone: true,
  imports: [CommonModule,CKEditorModule,ReactiveFormsModule],
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent implements OnInit {
  ngOnInit(): void {
  }
  
  @ViewChild('editor') editor!: CKEditorComponent;
  @Input() control!: FormControl;

  public Editor = Editor;
  configEditor:EditorConfig = {
    htmlSupport: {
		allow: [
			// Enables all HTML features.
			{
				name: /.*/,
				attributes: true,
				classes: true,
				styles: true,
			},
		],
	},}
  constructor(){
    try {
    } catch (error) {
    }
  }
  @Input()
  
  getEditor(){
    return this.editor.editorInstance;
  }
}
