<div class="w-full py-6 max-w-screen-md mx-auto">
    <div class="flex item">
        <div *ngFor="let step of steps, let i = index" class="text-xs md:text-base w-1/4 py-6">
            <div class="relative left-[50%]">
            <div class="{{current >= i?'font-[300] text-[20px]':'font-[300] text-[14px] text-[#B3B3B3]'}}">0.{{i+1}}</div>
            <div class="{{current >= i?'font-[600] text-[16px]':'font-normal text-[14px] text-[#B3B3B3]'}}">{{step.title}}</div>
            </div>
        </div>
    </div>
    <div class="flex">
      <div class="w-1/4" *ngFor="let step of steps, let i = index">
        <div class="relative mb-2">
          <div class="w-[18px] h-[16px] mx-auto {{step.checked?'bg-[#2CC784]': 'bg-transparent' }} rounded-full text-lg text-white flex items-center">
            <span class="text-center text-white w-full z-10">
                <svg *ngIf="step.checked" width="18" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.57199 1L3.8577 5.5L1.71484 3.45455" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
          </div>

          <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 0.5rem); top: 8px; transform: translate(50%, -50%)">
            <div class="w-full rounded items-center align-middle align-center flex-1">
              <div class="w-0 {{step.checked?'bg-[#2CC784] py-1': 'bg-[#B3B3B3] h-[1px]' }} rounded" style="width: 100%;"></div>
            </div>
          </div>
        </div>
  
        
      </div>
      
  
    </div>
  </div>

  <ng-content></ng-content>
  <div class="w-full border-t-[0.5px] border-[#B3B3B3] mx-auto mt-16 {{full?'':'max-w-screen-md'}}"></div>
  <div class=" mx-auto {{full?'':'max-w-screen-md'}}">
    <div class="flex gap-8 items-center justify-between py-10">
      <app-rounded-icon-buttom (btnClick)="prev()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass: current > 0 ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: current > 0 ? 'rotate-180' : '!invisible'}"/>
      <app-rounded-icon-buttom (btnClick)="next()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#2CC784]'}" />

    </div>
  </div>