<div>

    <div class="flex mt-2 mb-5">
        <div class="w-6/12">
            <p class="title-windows ">Organizations</p>
        </div>
        <div class="w-1/3 lg:w-6/12 p-2 align-right flex justify-end gap-8" style="text-align: right;">
            
            <div>
                <input class="form-field" (change)="bringOrgs(currentPage)" [formControl]="query" placeholder="Search..." type="text" id="name" name="name" ng-model="formData.name" required>
              </div>
              
          <!-- <button class="bt-filter p-2 ml-2 mr-2 flex text-center items-center" ng-click="alert()">Filters <img class="ml-1" width="13px" src="assets/img/icon/filter.svg" /></button> -->
          
          <app-button (click)="toggleModal()" [buttonData]="{classButton:'p-2',textButton:'Add New'}"></app-button>
        </div>
    </div>


    <div class="box-table mt-3">
        <table class="w-full">
            <thead class="mb-3">
                <tr>
                    <th class="text-left pl-2">Name</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Action</th>
                </tr>
            </thead>
            <tbody class="body-table">
                
                <tr class="row-table" *ngFor="let org of organizations">
                  <td class="rounded-l-lg pl-4">{{org.name}}</td>
                  <td [ngClass]="org.enabled?'text-green-500':'text-red-500'">{{org.enabled?'Enabled':'Disabled'}} </td>
                  <td class="rounded-r-lg" width="15px"> 
                    <app-actions [menu]="getMenu(org.realm,org)" />
                  </td>
                </tr>
  
            </tbody>
        </table>
        <app-paginator *ngIf="organizations.length" (btnAction)="bringOrgs($event)" [(current)]="currentPage" [size]="round(currentPageSize /10)" [current]="1" />
        <app-loading *ngIf="loading" />
      </div>

</div>


<app-modal title="Organization" #modal>
    <app-organization-form [id]="curId" [actions]="actions" ></app-organization-form>
</app-modal>

<app-modal title="Products" #productModal>
    <app-products-list #products [id]="curId"/>
    <div class="flex py-4 justify-end">
        <app-button (click)="productModal.toggleModal()" [buttonData]="{classButton:'w-[160px] p-2',textButton:'Save'}"></app-button>
    </div>
</app-modal>
