<div class="absolute"><app-modal [className]="'h-fit !py-10 p-4 !min-w-[20%]'" class="h-fit" #modal [title]="title">
    <div class="my-4">{{message}}</div>
    <ng-content ></ng-content>

    <div class="grid grid-cols-2 gap-2">
        <div class="">
        <app-button (click)="cancel()" [buttonData]="{textButton:'Cancel',classButton:'p-2 !bg-tranparent !w-full',classText:'!text-[white]'}"></app-button>
    </div>  
        <div class="">
        <app-button (click)="act()" [buttonData]="{textButton:'Delete',classButton:'p-2 !bg-[#FF00001a] !w-full border border-red-600',classText:'!text-[white]'}"></app-button>
    </div>
    </div>
</app-modal>
</div>