import { Injectable, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {COURSES, MODULES, MODULES_BY_CONTENT} from './queries'
import { Observable, Subject, map } from 'rxjs';
import { LOGIN } from './queries/login';
import Course from '../models/Course';
import Module from '../models/Module';
import Lecture from '../models/Lecture';
import { HttpClient } from '@angular/common/http';
import Quizz from '../models/Quizz';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EducationService  implements OnInit {
  tokken:string = "";
  apiBase = environment.FIXED_INCOME_API;
  constructor(private http: HttpClient,) {
    //super(apolloClient);
  }
  ngOnInit(): void {
  }

getById(type:any,id:any,lang:any):Observable<Course[]>{
  return this.http.get<Course[]>(this.apiBase + `/${type}/${id}/language/`+(lang || 'en')).pipe(map((result:any) => {
    let orgs:Course[] = []
    return result;
})
)
 }
  addTranlation(id:any,type:any,req:any,endpoint?:any):Observable<any>{
    return this.http.put<any>(this.apiBase + '/'+type+'/'+id + (endpoint? '/'+endpoint:''),req).pipe(map((result:any) => {
      return result;
  })
  )
   }
  publish(id:any,type:any,req:any){
    return this.http.put<Course[]>(this.apiBase + '/'+type+'/'+id+'/publish',req).pipe(map((result:any) => {
      let orgs:Course[] = []
      return result;
  })
  )
   }

   post(type:any,req:any){
    return this.http.post<any>(this.apiBase + '/'+type,req).pipe(map((result:any) => {
      return result;
  })
  )
   }

   update(type:any,id:string,req:any):Observable<Quizz>{
    return this.http.put<Quizz>(this.apiBase + '/'+type+'/'+id,req).pipe(map((result:any) => {
      return result;
  })
  )
   }

   
   delete(type:string,id:string):Observable<Course>{
    return this.http.delete<Course>(this.apiBase + '/'+type+'/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }


   getList(type:any,lang:any,params?:any):Observable<any |any[]>{
    return this.http.get<any[]|any>(this.apiBase + '/'+type+'/language/'+(lang || 'en')+
    (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
      return params?{content:result,size:result.totalPages}: result;
  })
  )
   }


  getCourses(lang:any,params?:any):Observable<Course[] | any>{
    return this.http.get<Course[]|any>(this.apiBase + '/courses/language/'+(lang || 'en')+ 
    (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
      let orgs:Course[] = []
      return params?{content:result.content,size:result.totalPages}: result.content || result;
  })
  )
   }

   getCourse(id:any,lang:any):Observable<Course[]>{
    return this.http.get<Course[]>(this.apiBase + `/courses/${id}/language/`+(lang || 'en')).pipe(map((result:any) => {
      let orgs:Course[] = []
      return result;
  })
  )
   }
   

   postCourses(org:Course):Observable<Course>{
    return this.http.post<Course>(this.apiBase + '/courses',org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   postCoursesTranslate(org:Course,id:any):Observable<Course>{
    return this.http.post<Course>(this.apiBase + '/courses/course/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   updateCourses(id:string,org:Course,lang:any):Observable<Course>{
    return this.http.put<Course>(this.apiBase + '/courses/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteCourses(id:string):Observable<Course>{
    return this.http.delete<Course>(this.apiBase + '/courses/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }


   getModules(lang:any,params?:any,anable?:boolean):Observable<Module[] | any> {
    console.log("START")
    return this.http.get<Module[]>(this.apiBase + '/content_module/language/'+(lang || 'en')+ (anable?"/enabled":"")+
    (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
      let orgs:Module[] = []
      return params? {content:result.content || result,size:result.totalPages}:result.content||result;
  })
  )
   }

   getModule(id:any,lang:any):Observable<Module[]>{
    console.log("START")
    return this.http.get<Module[]>(this.apiBase + `/content_module/${id}/language/`+(lang || 'en')).pipe(map((result:any) => {
      let orgs:Module[] = []
      return result;
  })
  )
   }

   getModulesByCourseId(courseId:string):Observable<Module[]>{
    return this.http.get<Module[]>(this.apiBase + '/content_module/course/'+courseId+"/language/en").pipe(map((result:any) => {
      let orgs:Module[] = []
      return result;
  })
  )
   }

   postModules(org:Module,id:number):Observable<Module>{
    return this.http.post<Module>(this.apiBase + '/content_module/course/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   updateModules(id:string,org:Module):Observable<Module>{
    return this.http.put<Module>(this.apiBase + '/content_module/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteModules(id:string):Observable<Module>{
    return this.http.delete<Module>(this.apiBase + '/content_module/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }

   
   getLectures(lang:any,params?:any):Observable<Lecture[] | any>{
    console.log("START")
    return this.http.get<Lecture[]|any>(this.apiBase + '/content-lectures/organization/language/'+(lang || 'en')+ 
    (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
      let orgs:Lecture[] = []
      return params? {content:result.content,size:result.totalPages} :result.content;
  })
  )
   }
   getLecturesByModuleId(moduleId:string,lang:string):Observable<Lecture[]>{
    return this.http.get<Lecture[] |any>(this.apiBase + '/content-lectures/content-modules/'+moduleId+'/language/'+lang).pipe(map((result:any) => {
      let orgs:Lecture[] = []
      return result;
  })
  )
   }
   getLecture(id:number,lang:string):Observable<Lecture[]>{
    console.log("START")
    return this.http.get<Lecture[]>(this.apiBase + '/content-lectures/'+id+'/language/'+(lang || 'en')).pipe(map((result:any) => {
      let orgs:Lecture[] = []
      return result;
  })
  )
   }

   postLectures(org:Lecture,id:any):Observable<Lecture>{
    return this.http.post<Lecture>(this.apiBase + '/content-lectures/content-modules/'+id,org).pipe(map((result:any) => {
      return result;
  })  
  )
   }
   updateLectures(id:string,org:Lecture):Observable<Lecture>{
    return this.http.put<Lecture>(this.apiBase + '/content-lectures/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteLectures(id:string):Observable<Lecture>{
    return this.http.delete<Lecture>(this.apiBase + '/content-lectures/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }


getQuizzes(lang:any,params?:any):Observable<Quizz[] | any>{
  console.log("START")
  return this.http.get<Quizz[] |any>(this.apiBase + '/quiz/language/'+(lang || "en")+ 
  (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
    let orgs:Quizz[] = []
    return params?{content:result.content,size:result.totalPages}:result.content
})
)
 }
 getQuizz(id:any,lang:string):Observable<Quizz[]>{
  console.log("START")
  return this.http.get<Quizz[]>(this.apiBase + '/quiz/'+id+'/language/'+(lang || "en")).pipe(map((result:any) => {
    let orgs:Quizz[] = []
    return result;
})
)
 }

 postQuizz(org:Quizz,id:any,qlId:any):Observable<Quizz>{
  return this.http.post<Quizz>(this.apiBase + '/quiz/content-modules/'+id +'/quiz-level/'+qlId,org).pipe(map((result:any) => {
    return result;
})  
)
 }
 updateQuizz(id:string,org:Quizz,qlId:any):Observable<Quizz>{
  return this.http.put<Quizz>(this.apiBase + '/quiz/'+id+'/quiz-level/'+qlId,org).pipe(map((result:any) => {
    return result;
})
)
 }
 deleteQuizz(id:string):Observable<Quizz>{
  return this.http.delete<Quizz>(this.apiBase + '/quiz/'+id).pipe(map((result:any) => {
    return result;
})
)
 }


 
getQuizzAnswers(id:any,lang:any):Observable<Quizz[]>{
  console.log("START")
  return this.http.get<Quizz[]>(this.apiBase + '/quiz-answer/quizzes/'+id+'/language/'+(lang || "en")).pipe(map((result:any) => {
    let orgs:Quizz[] = []
    return result;
})
)
 }
 getQuizzAnswer(id:any,lang:string):Observable<Quizz[]>{
  console.log("START")
  return this.http.get<Quizz[]>(this.apiBase + '/quiz-answer/quizzes/'+id/**+'/language/'+(lang || "en")**/).pipe(map((result:any) => {
    let orgs:Quizz[] = []
    return result;
})
)
 }

 postQuizzAnswer(org:any[],id:number):Observable<Quizz>{
  return this.http.post<Quizz>(this.apiBase + '/quiz-answer/quizzes/'+id,org).pipe(map((result:any) => {
    return result;
})  
)
 }
 updateQuizzAnswer(id:string,org:Quizz):Observable<Quizz>{
  return this.http.put<Quizz>(this.apiBase + '/quiz-answer/quizzes/'+id,org).pipe(map((result:any) => {
    return result;
})
)
 }
 deleteQuizzAnswers(id:string):Observable<Quizz>{
  return this.http.delete<Quizz>(this.apiBase + '/quiz-answer/'+id).pipe(map((result:any) => {
    return result;
})
)
 }
 sovelMessage(sms:any){
  let result = sms?.errorMessage?sms?.errorMessage + ",\t":sms.error || ""
  sms?.fieldErrorMessages?.forEach((element:any) => {
    result += (element?.field || "") + element.defaultMessage +",\t"
  });
  return result
}
}
