import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { TabsComponent } from './components/tabs/tabs.component';
import { BackAndForwardComponent } from './components/back-and-forward/back-and-forward.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './services/guard';
import { AuthInterceptor } from './services/http-interceptor.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuComponent } from './shared/Layouts/user-layout/side-menu/side-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



var path = window.location.pathname
var url = window.location.href
const realm =  path.split('/')[1]

console.log("Super REALM:",url)
if(url && !url.split('/')[1].split('/')[1]) url += "/"

function initializeKeycloak(
  keycloak: KeycloakService,
  keycloackAuth: Keycloak
) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloarUrl,
        realm: realm == 'admin'?'master':realm || "master",
        clientId: environment.keycloakClient,
      },
      initOptions: {
        redirectUri:url,
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      shouldUpdateToken: (request) => {
        if (request.headers.get('token-update') === 'false') {
          return false;
        } else {
          return true;
        }
      },
    });
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideMenuComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
  ],
  exports: [
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: APP_BASE_HREF, useValue: "/" + (realm || 'admin')+"/"},
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}