import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  thisYear: number;

  @Input() footerClass?: string = '';
  @Input() pClass?: string = '';

  constructor() {
    this.thisYear = new Date().getFullYear();
  }
}
