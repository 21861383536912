import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})
export class LevelComponent {
  selectedValue: number = 0;

  selectValue(index: number): void {
    this.selectedValue = index;
  }

}
