<div class="toaster" [class]="options.toastClass">
    <div class="toast-icon" [class]="options.toastClass">
      <img [src]="'assets/img/icons/'+options.toastClass+'.svg'" style="padding-right: 20px;" alt="check">
    </div>
    <div class="toast-details items-center">
      <div class="flex gap-2">
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
          class="custom-title"
        >
          {{ title }}:
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="ALERTA"
          aria-live="polite"
          class="custom-message"
          style="white-space: pre-line;"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="ALERTA"
          aria-live="polite"
          class="custom-message"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div>
      </div>
    </div>
    <div class="close-icon">
      <a
        *ngIf="options.closeButton"
        (click)="remove()"
        class="toast-close-button"
      >
        &times;
      </a>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  