import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() columns?: any[];
  @Input() data?: any[];
  get numColumns(): number {
    return this.columns ? this.columns.length : 0;
  }
}
