import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  @Input()
  dark:boolean = false
  sideMenu: any[];
  authenticated:boolean = false;
  constructor(private _router: Router,private keycloakService: KeycloakService){
    this.sideMenu = [
      { route: '/home', name: '',icon:'assets/img/icon/logo.svg'},

      { route: '/', name: 'Dashboard',icon:'assets/img/icon/Home.svg'},

      { route: '/config', name: 'config',icon:'assets/img/icon/config.svg'},

      { route: '/education', name: 'CMS',icon:'assets/img/icon/cms.svg'},
      { route: '/reporting', name: 'Reporting',icon:'assets/img/icon/reporting.svg'},
    ];

  }
  onMenuClick(i: number) {
    for (let index = 0; index < this.sideMenu.length; index++) {
      if (index === i) {
        this.sideMenu[index].ative = true;
      } else {
        this.sideMenu[index].ative = false;
      }
    }
    this._router.navigate([this.sideMenu[i].route]);
  }
  async isAuthenticaded(){
    this.authenticated = await this.keycloakService.isLoggedIn();
  }
  
  ngOnInit(): void {
    this.isAuthenticaded();
    const button = document.querySelector('#menu-button');
    const close = document.querySelector('#close-menu');
    const menu = document.querySelector('#menu');
    const nav = document.querySelector("#m-nav");
    if(button ) button.addEventListener('click', () => {
    if(menu)menu.classList.toggle('hidden');
    if(nav ){
      nav.classList.toggle('!bg-[#0C1026]'); nav.classList.toggle('h-screen');
      nav.classList.toggle('items-center');nav.classList.toggle('flex')
      nav.querySelector('#login')?.classList.toggle('hidden');
      nav.querySelector('#logsign')?.classList.toggle('!hidden');
      button.classList.add('hidden')
  }
  });

  if(close) close.addEventListener('click', () => {
    if(menu)menu.classList.toggle('hidden');
    if(nav ){
      nav.classList.toggle('!bg-[#0C1026]'); nav.classList.toggle('h-screen');
      nav.classList.toggle('items-center');nav.classList.toggle('flex')
      nav.querySelector('#login')?.classList.toggle('hidden');
      nav.querySelector('#logsign')?.classList.toggle('!hidden');
      if(button)button.classList.remove('hidden')
      
  }
  });
  }
  goToLogin() {
    this.keycloakService.login();
  }
  goToRegister() {
    this.keycloakService.register();
  }
}
