import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EducationService } from 'src/app/services/education.service';

@Component({
  selector: 'app-education-layout',
  templateUrl: './education-layout.component.html',
  styleUrls: ['./education-layout.component.scss']
})
export class EducationLayoutComponent implements OnInit {
  @Input()
  modules?: any[];
  completedPercentage: number;
  menuM = false
  constructor(
    private educationService:EducationService,
    private router:Router,
    private http: HttpClient){
    this.completedPercentage = 0
    console.log("modules",this.modules)
    
  }
  ngOnInit(): void {
    
  }
  toggleMenu(){
    this.menuM = !this.menuM;
  }
  goToEducation(){
    this.router.navigate(["/education"])
  }
  getCompletion(clicked:number,modules:number){
    

   
  }
  clickedIndices: number[] = [];
  clickedQuizIndices: number[] = [];


  // Rest of the component code...

  setClickedIndex(lectureIndex: number,modules:number,quiz:number) {
    

    const lectureClickedIndex = this.clickedIndices.indexOf(lectureIndex);
    if (lectureClickedIndex !== -1) {
      // If the clicked lecture index is already in the array, remove it
      this.clickedIndices.splice(lectureClickedIndex, 1);
    } else {
      // If the clicked lecture index is not in the array, add it
      this.clickedIndices.push(lectureIndex);
    }
    let totalIndex = this.clickedIndices.length + this.clickedQuizIndices.length
    let totalData = modules + quiz
    this.completedPercentage = totalIndex / totalData * 100;
    console.log("modules: clicked", totalIndex);
    console.log("modules: modules", totalData);
  }
  setClickedQuizIndex(quizIndex: number,modules:number,quiz:number) {
    const quizClickedIndex = this.clickedQuizIndices.indexOf(quizIndex);
    if (quizClickedIndex !== -1) {
      // If the clicked quiz index is already in the array, remove it
      this.clickedQuizIndices.splice(quizClickedIndex, 1);
    } else {
      // If the clicked quiz index is not in the array, add it
      this.clickedQuizIndices.push(quizIndex);
    }
    let totalIndex = this.clickedIndices.length + this.clickedQuizIndices.length
    let totalData = modules + quiz
    this.completedPercentage = totalIndex / totalData * 100;    
  }
  
  // Function to check if a lecture index is clicked or not
  isClicked(lectureIndex: number): boolean {
    
    return this.clickedIndices.includes(lectureIndex);
  }
  isClickedQuizIndex(quizIndex: number): boolean {
    return this.clickedQuizIndices.includes(quizIndex);
  }
  setCurrentLecture(content:any[]
  ){
  }
}
