import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputForm } from 'src/app/models/ComponentInput';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { FormControl, ReactiveFormsModule,} from '@angular/forms';
import { TextEditorComponent } from 'src/app/shared/text-editor/text-editor.component';

@Component({
  selector: 'app-input-form',
  standalone: true,
  imports: [CommonModule, RoundedIconButtomComponent,ReactiveFormsModule,TextEditorComponent],
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent implements OnInit {
  // @Input() isEditable: boolean = true;import { FormControl } from '@angular/forms';

  
  @ViewChild('editor') editor!: TextEditorComponent;
  @Input() control: FormControl = new FormControl()
  @Input() inputData: InputForm = {
    inputFormClass: '',
    label: 'Full Name',
    placeholder: 'My Address',
    inputType: 'text',
    labelClass: '',
    inputClass: '',
    id: '',
    name: '',
    isEditable: false,
  };

  @Output() btnCheckClick = new EventEmitter<void>();
  
  showCheck: boolean = false;
  @Input()
  disabled: boolean = false;

  editableCLass: string = '';

  ngOnInit(): void {
    if (this.inputData.isEditable) {
      this.editableCLass = '!bg-transparent';
      this.disabled = true;
    }
  }

  onEditClick() {
    this.editableCLass = '';
    this.disabled = false;
    this.showCheck = true;
  }

  onCheckClick() {
    this.editableCLass = '!bg-transparent';
    this.disabled = true;
    this.showCheck = false;
    this.btnCheckClick.emit();
  }
  getEditor(){
    return this.editor.getEditor()
  }
}
