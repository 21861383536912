
<div class="w-full py-6 mx-auto">
  <div class="flex item">
    <div class="text-xs md:text-base w-1/4 py-6">
      <div class="relative {{quiz || lecture?'':'left-[18%] md:left-[68%]'}}">
        <span class="font-[300] text-[28px] ">{{ (current+1) | number: '2.0' }}</span>
        <span class="font-[300] text-[14px]  text-[#6761A8]"> / {{steps!.length}}</span>
        <div *ngIf="quiz">
          <div *ngIf="quizAnswer">WellDone</div>
          <div *ngIf="quizAnswer == false">Incorrect</div>
          <div *ngIf="quizAnswer == null"></div>


        </div>
        <div *ngIf="!quiz" class="font-normal text-[19px] text-[#FFFFFF] whitespace-nowrap">{{steps![current].title}}</div>
      </div>
    </div>
  </div>
  <div class="w-full border-t-[0.5px] border-[#B3B3B3] {{quiz || lecture?'':'max-w-lg'}} mx-auto mt-20"
    style="border-color: transparent; margin-top: 0;">
    <div class="relative">
      <div class="w-0 bg-[#2CC784] py-1 rounded" [style.width]="steps![current].checked?'100%':((current) / (steps!.length )) * 100 + '%'"></div>
      <div class="absolute inset-0 flex align-center items-center align-middle content-center" style="top: 8px;">
      </div>
      <div class="absolute inset-0 flex align-center items-center align-middle content-center">
        <div class="w-0 bg-[#B3B3B3] h-[1px] rounded" style="width: 100%;"></div>
      </div>
    </div>
  </div>

</div>


<ng-content></ng-content>
<div class="border-t-[0.5px] border-[#B3B3B3] mx-auto mt-16  {{full?'w-full':'max-w-screen-md'}}">

  <div class=" mx-auto  {{full?'':'max-w-screen-md'}}">
    <div class="flex gap-8 items-center justify-between py-10">
      <app-rounded-icon-buttom (btnClick)="prev()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass: current > 0 ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: current > 0 ? 'rotate-180' : '!invisible'}"/>
      <app-rounded-icon-buttom (btnClick)="next()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#2CC784]'}" />

    </div>
  </div>
</div>

<!-- <app-back-and-forward (nextClick)="next()" (prevClick)="prev()" /> -->