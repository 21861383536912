import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import Product  from '../models/Product';
import Widget from '../models/Widget';
import { Organization } from '../models/Organization';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  apiBase = environment.ADMIN_API;
  constructor(private http:HttpClient) {
   }

   
   getTriggers():Observable<any[]>{
    console.log("START")
    return this.http.get<Product[]>(this.apiBase + '/triggers').pipe(map((result:any) => {
      let orgs:Product[] = []
      return result;
  })
  )
   }
   getProducts(orgId?:any):Observable<Product[]>{
    console.log("START")
    return this.http.get<Product[]>(this.apiBase +(orgId?"/organizations/"+orgId:"")+ '/products').pipe(map((result:any) => {
      let orgs:Product[] = []
      return result.items;
  })
  )
   }
   getProduct(id:any,orgId?:any):Observable<Product>{
    return this.http.get<Product[]>(this.apiBase + + '/products/'+id).pipe(map((result:any) => {
      let orgs:Product[] = []
      return result;
  })
  )
   }
   

   postProducts(org:Product):Observable<Product>{

    console.log(this.http.post<Product>(this.apiBase + '/products',org).pipe(map((result:any) => {
      return result;
  })
  ))
    return this.http.post<Product>(this.apiBase + '/products',org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   updateProducts(id:string,org:Product|null,orgId?:any):Observable<Product>{
    return this.http.put<Product>(this.apiBase + (orgId?"/organizations/"+orgId:"")+ '/products/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteProducts(id:string,orgId?:any):Observable<Product>{
    return this.http.delete<Product>(this.apiBase + (orgId?"/organizations/"+orgId:"")+'/products/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }

   getAllWidgets(params?:any):Observable<Widget[] |any>{
    console.log("START")
    return this.http.get<Widget[]|any>(this.apiBase + '/widgets'+ 
    (params? '?' + new URLSearchParams(params).toString():"")).pipe(map((result:any) => {
      let orgs:Widget[] = []
      return params?{content:result,size:result.totalItems}:result;
  })
  )
   }

   getWidgets(pId?:any,orgId?:any):Observable<Widget[]>{
    console.log("START")
    return this.http.get<Widget[]>(this.apiBase +(orgId?"/organizations/"+orgId:"")+(pId?'/products/'+pId:"")+'/widgets').pipe(map((result:any) => {
      let orgs:Widget[] = []
      return result.items;
  })
  )
   }
   getWidget(id:any,pId?:any,orgId?:any):Observable<Widget>{
    return this.http.get<Widget[]>(this.apiBase +(orgId?"/organizations/"+orgId:"")+(pId?'/products/'+pId:"")+'/widgets/'+id).pipe(map((result:any) => {
  
      let orgs:Widget[] = []
      return result;
  })
  )
   }

   postWidgets(pId:any,org:Widget):Observable<Widget>{

    console.log(this.http.post<Widget>(this.apiBase + '/products/'+pId+'/widgets',org).pipe(map((result:any) => {
      return result;
  })
  ))
    return this.http.post<Widget>(this.apiBase + '/products/'+pId+'/widgets',org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   updateWidgets(id:string,org:Widget,pId?:any,orgId?:any):Observable<Widget>{
    return this.http.put<Widget>(this.apiBase + (orgId?"/organizations/"+orgId:"")+(pId?'/products/'+pId:"")+'/widgets/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteWidgets(id:string,pId?:any,orgId?:any):Observable<Widget>{
    return this.http.delete<Widget>(this.apiBase + (orgId?"/organizations/"+orgId:"")+(pId?'/products/'+pId:"")+ '/widgets/'+id).pipe(map((result:any) => {
      return result;
  })
  )
  
   }



   getOrganizations(params?:any,search?:any):Observable<any>{
    console.log("START")
    return this.http.get<any>(this.apiBase + '/organizations'+(search?'/search':'')+
    (params? '?' + new URLSearchParams(params).toString():"")
    ).pipe(map((result:any) => {
      let orgs:Organization[] = []
      return {data:result.items,size:result.totalItems};
  })
  )
   }
   getOrganization(id:any):Observable<Organization>{
    return this.http.get<Organization[]>(this.apiBase + '/organizations/'+id).pipe(map((result:any) => {
      let orgs:Organization[] = []
      return result;
  })
  )
   }

   postOrganizations(org:Organization):Observable<Organization>{

    console.log(this.http.post<Organization>(this.apiBase + '/organizations',org).pipe(map((result:any) => {
      return result;
  })
  ))
    return this.http.post<Organization>(this.apiBase + '/organizations',org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   updateOrganizations(id:string,org:Organization):Observable<Organization>{
    return this.http.put<Organization>(this.apiBase + '/organizations/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteOrganizations(id:string):Observable<Organization>{
    return this.http.delete<Organization>(this.apiBase + '/organizations/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }




   getUsers(orgId:any):Observable<Organization[]>{
    console.log("START")
    return this.http.get<any[]>(this.apiBase + '/organizations/'+orgId+'/users').pipe(map((result:any) => {
      return result.items;
  })
  )
   }
   getUser(id:any):Observable<Organization>{
    return this.http.get<Organization[]>(this.apiBase + '/users/'+id).pipe(map((result:any) => {
      let orgs:Organization[] = []
      return result;
  })
  )
   }

   postUsers(org:any,orgId:any):Observable<Organization>{

    return this.http.post<Organization>(this.apiBase + '/organizations/'+orgId+'/users',org).pipe(map((result:any) => {
      return result;
  })
  )
    
   }
   updateUser(id:string,org:Organization):Observable<Organization>{
    return this.http.put<Organization>(this.apiBase + '/users/'+id,org).pipe(map((result:any) => {
      return result;
  })
  )
   }
   deleteUser(id:string,orgId:any):Observable<Organization>{
    return this.http.delete<Organization>(this.apiBase + '/organizations/'+orgId+'/users/'+id).pipe(map((result:any) => {
      return result;
  })
  )
   }





getLanguages():Observable<any[]>{
  return this.http.get<any[]>(this.apiBase + '/languages/all').pipe(map((result:any) => {

    return result;
})
)
 }
getEnabledLanguages():Observable<any[]>{
  return this.http.get<any[]>(this.apiBase + '/languages/enabled').pipe(map((result:any) => {

    return result;
})
)
 }
 getLanguage(id:any):Observable<any>{
  return this.http.get<any[]>(this.apiBase + '/languages/'+id).pipe(map((result:any) => {
    return result;
})
)
 }

 postLanguages(org:any):Observable<any>{

  console.log(this.http.post<any>(this.apiBase + '/languages',org).pipe(map((result:any) => {
    return result;
})
))
  return this.http.post<any>(this.apiBase + '/languages',org).pipe(map((result:any) => {
    return result;
})
)
 }
 updateLanguages(id:string,org:any):Observable<any>{
  return this.http.put<any>(this.apiBase + '/languages/'+id,org).pipe(map((result:any) => {
    return result;
})
)
 }
 deleteLanguages(id:string):Observable<any>{
  return this.http.delete<any>(this.apiBase + '/languages/'+id).pipe(map((result:any) => {
    return result;
})
)
 }
 /**
  * 
  * @param sms 
  * @returns 
  */
 sovelMessage(sms:any){
  let result =  ""
  
  if(!(sms.message instanceof Array))result = sms.message
  else sms.message.forEach((element:any) => {
    result += element +",\t"
  });

  return result
}
}
