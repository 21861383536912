import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './services/guard';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard],data:{roles:['ADMIN']}},
  
  
  {
    path: 'education',
    loadChildren: () => import('./views/education/education.module').then(m => m.EducationModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: '',
    loadChildren: () => import('./views/superadmin/superadmin.module').then(m => m.SuperadminModule)
  },
  //Pessoal Area
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
