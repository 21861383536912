import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  title:any = null
  message:any = null
  action:any = () =>{}
  curTab = 0;
  id:any = null
  constructor(private route:ActivatedRoute,
    private toast: ToastrService,
    private router: Router,
    private adminService:AdminService){

  }


  @ViewChild('dialog', { static: false }) dialog!: DialogComponent;

 
  toggleDialog(title:string,message:string,action:any){
    this.title = title
    this.action = action
    this.message = message
    if(this.dialog)this.dialog.toggleModal();

  }
  delete = {name:'Delete Organization',action:()=>this.toggleDialog('Delete Organization','Sure you wnat to delete Organization',()=>this.deleteOrg())}
  tabs = [
    {name:'About Organization',action:()=>this.selectTab(0)},
  {name:'Product',action:()=>this.selectTab(1)},
  {name: 'Users',action:()=>this.selectTab(2)}
]

  selectTab(tab:number) {
    this.curTab = tab
  }
  deleteOrg() {
    this.adminService.deleteOrganizations(this.id).subscribe({next:data => {

      this.toast.success('Organization Deleted', 'SUCESS',{toastClass: "custom-toast-success"})
      this.router.navigate(['/config'])
    },
    error: error => {
      this.toast.success(error.error?.message, 'ERROR',{toastClass: "custom-toast-error"})
    }
  });
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      var init = params['tab'];
      if(init) this.curTab = 1
      
    });
    this.id = this.route.snapshot.params['id'];
  }

}
