import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input()
  className?:string;
  @Input()
  title?:string;
  isOpen:boolean = false;


  toggleModal() {
    this.isOpen = !this.isOpen;
    return this.isOpen
  }
  closeModal(){
    this.isOpen = false
  }

}
