<div class="flex gap-1 py-2 px-3 bg-[#0C1026] rounded mt-4 mb-2">
     <div id="very_low"
          [class]="'h-2 ' + (selectedValue >= 1 && selectedValue <=5 ? 'bg-[#2CC784]' : 'bg-[#98F2B933]') + ' flex-grow rounded-2xl'"
          (click)="selectValue(1)">
     </div>
     <div id="low"
          [class]="'h-2 ' + (selectedValue >= 2 && selectedValue <= 5 ?'bg-[#2CC784]' : 'bg-[#98F2B933]') + ' flex-grow rounded-2xl'"
          (click)="selectValue(2)">
     </div>
     <div id="medium"
          [class]="'h-2 ' + (selectedValue >= 3 && selectedValue <= 5 ? 'bg-[#2CC784]' : 'bg-[#98F2B933]') + ' flex-grow rounded-2xl'"
          (click)="selectValue(3)">
     </div>
     <div id="high"
          [class]="'h-2 ' + (selectedValue >= 4 && selectedValue <= 5 ? 'bg-[#2CC784]' : 'bg-[#98F2B933]') + ' flex-grow rounded-2xl'"
          (click)="selectValue(4)">
     </div>
     <div id="very_high"
          [class]="'h-2 ' + (selectedValue === 5 ? 'bg-[#2CC784]' : 'bg-[#98F2B933]') + ' flex-grow rounded-2xl'"
          (click)="selectValue(5)">
     </div>
</div>
<div class="flex justify-between ">
     <div>Very Low</div>
     <div>Very High</div>
</div>