import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EducationService } from 'src/app/services/education.service';
import { ModalComponent } from '../modal/modal.component';
import Module from 'src/app/models/Module';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  loading:boolean = false
  saving:boolean = false
  users: any[] = []
  @Input()
  orgID= null
  id: any = null;
  languages?: any;
  constructor(
    private adminService:AdminService,
    private toast:ToastrService,private router:Router, private fb: FormBuilder) {


  }

@ViewChild('modal', { static: false }) modal!: ModalComponent;



name = new FormControl;
email = new FormControl
firstName = new FormControl
lastName = new FormControl;
lang = new FormControl;
qAs:any[] = []

form = this.fb.group({
  name: this.name,
  email: this.email,
  lastName: this.lastName,
  firstName: this.firstName
});

addQAs(form?:any) {
  const val = {
    name:new FormControl(form.content, [])
  }
 

  this.qAs.push(val);
}
deleteQAs(priceIndex: number) {
  //this.qAs.removeAt(priceIndex);
}

toggleModal(id:any = null) {
  if(this.modal)this.modal.toggleModal();
  if(id) {
    this.id = id
    //this.getUser(id)
  }
  else {
    this.form.reset()
    this.id = null
  }
  this.bringUsers()
}
  ngOnInit(): void {

    this.adminService.getEnabledLanguages().subscribe(data => {
      this.languages = data.map(m => {
        return {value:m.code,name:m.name}
      });
    })
    this.bringUsers()



    
  }
  getMenu(id:any,a:any){
    return [
    {name:'Delete',message:'Are you sure you want to delete this',type:'comfirm',action:()=>{
      this.adminService.deleteUser(id,this.orgID).subscribe(data => {

        this.toast.success('User Deleted', 'SUCESS',{toastClass: "custom-toast-success"})
        this.bringUsers();
      });
    }},
  ]
  }
  bringUsers(){
    this.loading = true
    this.adminService.getUsers(this.orgID).subscribe({next:(data:any) => {
      this.users = data
      //this.modules = data.map((c:any)=>  ({ value: c.id, name: c.moduleTranslationDto.name }))
      this.loading =false
      console.log(data)
    },error:(err) => this.loading= false ,})

  }

  create(){
    this.router.navigate(['/module/create'])
  }
  save(){
  this.saving = true
  let data:any = {
    //level: this.form.value.id,
    "username": this.form.value.name,
  "email": this.form.value.email,
  "firstName": this.form.value.firstName,
  "lastName": this.form.value.lastName,
    }
  
  if(!this.id){
    this.adminService.postUsers(data,this.orgID).subscribe({next:data =>  {
      this.toast.success('User Added', 'SUCESS',{toastClass: "custom-toast-success"});
      this.toggleModal()
      this.saving = false
    },error:(error:any) => {
      this.toast.error(error.error.message, 'ERROR',{toastClass: "custom-toast-error"})
      this.saving = false
      
    },})
  }else {
    this.adminService.updateUser(this.id,data).subscribe({next:data =>  {
      this.toast.success('User Updated', 'SUCESS',{toastClass: "custom-toast-success"});
      this.toggleModal()
      this.saving = false
    },error:(error:any) => {
      this.toast.error(error.error.message, 'ERROR',{toastClass: "custom-toast-error"})
      this.saving = false
      
    },})
  }

  }

}
