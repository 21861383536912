import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { YieldvestorComponent } from './yieldvestor/yieldvestor.component';
import { RoundedIconButtomComponent } from '../components/rounded-icon-buttom/rounded-icon-buttom.component';
import { UserLayoutComponent } from './Layouts/user-layout/user-layout.component';
import { ButtonComponent } from '../components/button/button.component';
import { StepperComponent } from './stepper/stepper.component';
import { StepperCounterComponent } from './stepper-counter/stepper-counter.component';
import { BackAndForwardComponent } from '../components/back-and-forward/back-and-forward.component';
import { TableComponent } from './table/table.component';
import { RouterModule } from '@angular/router';
import { MobileMenuComponent } from './Layouts/user-layout/mobile-menu/mobile-menu.component';
import { SideBarComponent } from './Layouts/user-layout/side-bar/side-bar.component';
import { InputFormComponent } from '../components/input-form/input-form.component';
import { SelectFormComponent } from '../components/select-form/select-form.component';
import { RangeSliderComponent } from '../components/range-slider/range-slider.component';
import { LevelComponent } from '../components/level/level.component';
import { EducationLayoutComponent } from './Layouts/education-layout/education-layout.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationFormComponent } from './organization-form/organization-form.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { EditOrgComponent } from './organizations/edit-org/edit-org.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { ConfigComponent } from './config/config.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TabsComponent } from '../components/tabs/tabs.component';
import { ActionsComponent } from './actions/actions.component';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './custom-toast/custom-toast.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './dialog/dialog.component';
import { UsersComponent } from './users/users.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { LoadingComponent } from '../components/loading/loading.component';


export const COMMON_IMPORTS = [
  RoundedIconButtomComponent,
  TabsComponent,
  SelectFormComponent,
  ButtonComponent,
  InputFormComponent,
  LoadingComponent,
];
@NgModule({
  declarations: [
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    StepperComponent,
    StepperCounterComponent,
    TableComponent,
    MobileMenuComponent,
    SideBarComponent,
    EducationLayoutComponent,
    OrganizationsComponent,
    OrganizationFormComponent,
    ProductsListComponent,
    EditOrgComponent,
    ModalComponent,
    ConfigComponent,
    ActionsComponent,
    CustomToastComponent,
    DialogComponent,
    UsersComponent,
    DropdownComponent,
    PaginatorComponent,
    
  ],
  exports: [
    FooterComponent,
    YieldvestorComponent,
    UserLayoutComponent,
    TableComponent,
    MobileMenuComponent,
    EducationLayoutComponent,
    OrganizationsComponent,
    OrganizationFormComponent,
    ProductsListComponent,
    UsersComponent,
    DropdownComponent,
    ActionsComponent,
    ModalComponent,
    PaginatorComponent,
    ...COMMON_IMPORTS
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    RoundedIconButtomComponent,
    ButtonComponent,
    BackAndForwardComponent,
    RouterModule,
    InputFormComponent,
    SelectFormComponent,
    RangeSliderComponent,
    LevelComponent,
    ButtonComponent,
    ReactiveFormsModule,
    TabsComponent,
    TextEditorComponent,
    LoadingComponent,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      closeButton: true,
      timeOut: 3000,
      positionClass: 'toast-top-center',
      toastClass: "custom-toast-warning",
      preventDuplicates: true,
    }),
    
  ]
})
export class SharedModule { }
