import { Component, EventEmitter, Input, Output } from '@angular/core';
interface Steps {
  title:string,
  checked?:boolean | null,
}
@Component({
  selector: 'app-stepper-counter',
  templateUrl: './stepper-counter.component.html',
  styleUrls: ['./stepper-counter.component.scss']
})
export class StepperCounterComponent {
  @Input() full = true
  @Input() quiz:boolean = false;
  @Input() lecture:boolean = false;
  @Input() steps:Steps[] = [{title:'None',checked:false}];
  @Input() quizAnswer:boolean | null = null
  @Input() current:number = 1;
  @Output() nextClick = new EventEmitter<void>();
  @Output() prevClick = new EventEmitter<void>();
  
  
  next() {
    this.nextClick.emit();
  }

  prev() {
    this.prevClick.emit();
  }


}
