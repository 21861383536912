import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { YildVestor } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-yieldvestor',
  templateUrl: './yieldvestor.component.html',
  styleUrls: ['./yieldvestor.component.scss'],
})
export class YieldvestorComponent {
  constructor(private router:Router){
  this.url = router.url
  }
  url:string = ""
  @Input()
  logo: boolean = true;
  @Input()
  alternate: boolean = false;

  @Input() yieldVestorData: YildVestor = {
    firstText: ' Yield',
    secondText: 'vestor',
    logo: 'assets/img/icon/logo.svg',
    firstTextClass: '',
    secondTextClass: '',
  };
}
