import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  loading:boolean = false
  @Input() id = null;
  products:any[] = []
  widgets: any = []
  constructor(private adminService:AdminService){}
  ngOnInit(): void {
   this.bringProducts()
  }
  bringProducts(id?:any){
    this.loading =true
    this.adminService.getProducts(id||this.id).subscribe({next:(data:any)=> {
      this.products = data;
      this.widgets =data
      console.log(this.products)
      this.loading = false
    },error:(err)=>this.loading = false,})
  }

  toggleAccordion(event: Event,pId:any = null) {
    const button = event.target as HTMLElement;
    const content = button.parentElement?.nextElementSibling as HTMLElement;
    const arrow = document.getElementById("arrow-"+pId) as HTMLElement;
    content.classList.toggle('block');
    arrow.classList.toggle('rotate');
    if(pId) this.adminService.getWidgets(pId).subscribe({next:(data:any) => {

      console.log(data)
      if(data)this.widgets[pId] = data;
    },complete() {
      
    content.classList.toggle('scaleY');
    },})
  }
  async updateWidget(pId:any,w:any){
    this.loading = true
    w.enabled?
    this.adminService.deleteWidgets(w.id,pId,this.id).subscribe((data:any) => {
      console.log(data)
      w.enabled = !w.enabled
      this.getWidgets(pId)
    })
    :
    this.adminService.updateWidgets(w.id,{
      code: w.code,
      enabled: !w.enabled
    },pId,this.id).subscribe((data:any) => {
      console.log(data)
      w.enabled = !w.enabled
      if(data)this.getWidgets(pId)
    })
  
    this.loading = false
  }
  updateProduct(pId:any,prod:any){
    (prod.enabled?
      this.adminService.deleteProducts(pId,this.id)
      :
    this.adminService.updateProducts(pId,
      null,this.id
    )).subscribe((data:any) => {
      console.log(data)
      prod.enabled = !prod.enabled
      if(data)this.adminService.getProducts(this.id).subscribe((data:any)=> {
        this.products = data;
      })
    })
  }
  getWidgets(pId:any){
    
    try {
      console.log(this.widgets[pId])
      return this.widgets[pId] || []
    } catch (err) {
      return []
    }
  }

}
