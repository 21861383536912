<div
  [routerLink]="logo ? '/' : null"
  class="{{ logo ? 'p-4 cursor-pointer' : '' }} flex items-center"
>
  <img
    *ngIf="yieldVestorData.logo && logo"
    src="{{ yieldVestorData.logo }}"
    class="me-2 ms-0"
  />
  <div class="text-[#4A4C57] font-bold">
    <span class="text-[#2CC784] {{ yieldVestorData.firstTextClass }}">{{
      yieldVestorData.firstText
    }}</span
    ><span class="{{ yieldVestorData.secondTextClass }}">{{
      yieldVestorData.secondText
    }}</span>
    <br />
  </div>
</div>

<!-- <span class="text-[#2CC784]"> Yield</span><span>Vestor</span> <br /> -->
