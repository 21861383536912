<div [class.modalOpen]="isOpen" 
[ngClass]="{
    'h-[90vh]':isOpen,
    'opacity-0 pointer-events-none':!isOpen
}" 
class="modal overflow-auto mx-auto fixed top-0 left-1/2 -translate-x-1/2 max-w-full min-w-[50%] md:mx-20  !bg-[#0C1026Fa] text-white z-40 duration-150 transform -translate-y-full {{className}}">

    <div class="flex items-center mb-10 ">
        <div class=" font-normal text-[2rem] flex-grow">{{title}}</div>
        <app-rounded-icon-buttom (btnClick)="toggleModal()" [buttonData]="{
            iconUrl:'assets/img/icon/cross.svg'
        }" />
        </div>
   <ng-content></ng-content>

  </div>

<div (click)="toggleModal()" [ngClass]="{
    '!bg-opacity-75':isOpen,
    'bg-opacity-0 -translate-y-full':!isOpen
}" class="fixed top-0 left-0 h-screen w-full bg-black z-30 transition-opacity duration-300"></div>