<div *ngFor="let module of modules; let moduleIndex = index">
    <div class="flex justify-end gap-[1rem] mr-[5rem]">
        <div class="text-[#978EF5] text-[0.875rem] cursor-pointer" (click)="goToEducation()">My Learnings</div>
        <div class="text-[#B3B3B3] text-[0.875rem]">/</div>
        <div class="text-[#B3B3B3] text-[0.875rem]">{{module.course.data.attributes.title}}</div>

    </div>
    <div class="md:flex items-center justify-between mt-[2rem]">
        <div class="">
            <div class="text-[1.375rem] text-[#978EF5]">{{module.course.data.attributes.title}}</div>
            <div class="text-[8vw] md:text-[3rem]">{{module.title}}</div>
        </div>
        <div class="bg-[#4946681A] rounded-[2rem] p-[2rem]">
            <span class="text-[1rem] text-[#B3B3B3]"></span>
            <div class="flex justify-between gap-[2rem] items-center">
                <span class="text-[#B3B3B3] text-[1rem]">
                    <span class="text-[#2CC784] text-[2rem]">{{ completedPercentage | number:'1.0-0' }}%</span>
                    completed
                </span>
                <span class="text-[#978EF5] text-[1.25rem]"> 0 of 10 points</span>
            </div>

            <div id="progressBar">
                <div id="progressLine" class="border-t-[0.5px] border-[#B3B3B3] mx-auto mt-20"
                    style="border-color: transparent; margin-top: 0;">
                    <div class="w-full bg-[#F0EDF6] h-[0.5rem] rounded">
                        <div class="w-[2px] bg-[#2CC784] h-full rounded" [style.width]="completedPercentage + '%'">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="md:flex">
    <div (click)="toggleMenu()" class="md:hidden inline-flex items-center mt-8 bg-[#ffffff1a] px-2 pr-4 cursor-pointer rounded-2xl">
    <app-rounded-icon-buttom  *ngIf="!menuM"  [buttonData]="{iconUrl:'assets/img/icon/menu.svg',iconClass:'h-[1.5rem] brightness-100',btnClass:'z-50 !bg-transparent'}" />
        Items Menu
    </div>
    <div [ngClass]="{'md:w-1/3 w-full hidden md:block mt-[3rem]':!menuM,'block fixed left-0 top-0 bg-yv z-50 p-8 w-full h-screen':menuM}">
        <span id="close-menu" ><app-rounded-icon-buttom (btnClick)="toggleMenu()" *ngIf="menuM" [buttonData]="{iconUrl:'assets/img/icon/cross.svg',btnClass:'my-4',iconClass:'brightness-100 invert'}" /></span>
        <div class="text-[1.25rem] font-[600] mb-[0.8rem]">Course summary</div>
        <div *ngFor="let module of modules; let moduleIndex = index">
            <div class="ml-[1.8rem] mb-[0.8rem] text-[1rem] font-[600] text-[#FFFFFF]"> {{module.title}} Lecture</div>
            <div  *ngFor="let lecture of module.lectures; let lectureIndex = index" class="ml-[1.8rem] mb-[0.8rem] flex flex-col">
                <div class="flex items-center text-[0.938rem]"
                    (click)="setCurrentLecture(module.lectures);
                    setClickedIndex(lectureIndex,module.lectures.length,module.quizzes.length?1:0)
                    ">
                    <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                        [ngStyle]="{ 'background-color': isClicked(lectureIndex) ? '#81FFAF' : '#FFFFFF' }"></div>
                    {{ lecture.title }}
                </div>
            </div>

            <div *ngIf="module.quizzes.length" class="ml-[1.8rem] mb-[0.8rem] flex flex-col">
                <div class="flex items-center text-[0.938rem]"
                (click)="
                    setClickedIndex(module.lectures.length,module.lectures.length,module.quizzes.length?1:0)
                    "
                    >
                    <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                        [ngStyle]="{ 'background-color': isClicked(module.lectures.length) ? '#81FFAF' : '#FFFFFF' }"></div>
                    {{ module.title}} Quizzes
                </div>
            </div>
        </div>

<!-- 
        <div *ngFor="let module of modules; let moduleIndex = index">
            <div class="ml-[1.8rem] mb-[0.8rem] text-[1rem] font-[600] text-[#FFFFFF]"> {{module.attributes.title}} Quiz</div>
            <div *ngFor="let quiz of module.attributes.quizzes.data; let quizIndex = index" class="ml-[1.8rem] mb-[0.8rem] flex flex-col">
                <div class="flex items-center text-[0.938rem] w-[20rem]"
                (click)="setClickedQuizIndex(quizIndex,module.attributes.contentLectures.length,module.attributes.quizzes.data.length)"
                  >
                    <div class="bg-white mr-[0.5rem] w-[0.75rem] h-[0.75rem] rounded-full p-1"
                        [ngStyle]="{ 'background-color': isClickedQuizIndex(quizIndex) ? '#81FFAF' : '#FFFFFF' }"></div>
                    {{ quiz.attributes.title }}
                </div>
            </div>
        </div> -->

        
    </div>


    <div class="md:w-2/3 w-full">
        <ng-content></ng-content>
    </div>
</div>