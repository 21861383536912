import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  secoundSideMenu: any[];

  constructor(private _router: Router,){

    this.secoundSideMenu=[
      {
        menu: 'notification',
        itensMenu : 
        [
          { route: "'/notification'", name: 'Notification List',icon:'assets/img/icon/Home.svg',role:['admin','organization']},
          { route: "'/template'", name: 'Templates',icon:'assets/img/icon/Home.svg',role:['admin','organization']},
          { route: "'/notification-configuration'", name: 'Configurations',icon:'assets/img/icon/Home.svg',role:['admin','organization']},
        ]
      }
    ];

  }


  isNotificationMenu(){
    return this._router.url.split("/")[1] == "notification" || this._router.url.split("/")[1] == "template" || this._router.url.split("/")[1] == "notification-configuration" 
  }

  getNotificationMenu(){
    return this._router.url.split("/")[1]
  }


}
