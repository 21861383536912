<app-user-layout>
<div class="flex justify-between gap-8 mb-10">
    <app-tabs [current]="curTab" [tabsData]="{active:'!bg-[#2CC784]',class:'gap-0 bg-[#FFFFFF11] !p-1 mb-9 rounded-full',classTab:'border-0'}"
    
[tabs]="tabs"/>

<app-tabs [current]="0" [tabsData]="{active:'!bg-[#F00]',class:'gap-0 bg-[#FFFFFF11] !p-1 mb-9 rounded-full',classTab:'border-0'}"
    
[tabs]="[delete]"/>
</div>
<ng-container [ngSwitch]="curTab">
<div class="rounded-2xl bg-[#ffffff11] md:p-10 p-5">

<app-organization-form *ngSwitchCase="0"  [id]="id"/>
<app-products-list *ngSwitchCase="1"  [id]="id"  />
<app-users *ngSwitchCase="2" [orgID]="id" />
</div>
</ng-container>

<app-dialog #dialog [title]="title" [message]="message" (action)="action()"></app-dialog>
</app-user-layout>