<div class="mx-auto">
  <div class="flex gap-8 items-center justify-between py-10">
    <app-rounded-icon-buttom (btnClick)="prev()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass: current > 0 ? 'bg-[#4A4C57]' : '!bg-transparent', iconClass: current > 0 ? 'rotate-180' : '!invisible'}"></app-rounded-icon-buttom>
    <ng-container *ngIf="current === totalSteps - 1">
      <app-button (click)="end()" [buttonData]="{textButton:'Get Advice',classButton:'!bg-[#6761A8]',classText:'!text-[white]'}"></app-button>
    </ng-container>
    <ng-container *ngIf="current < totalSteps - 1">
      <app-rounded-icon-buttom (btnClick)="next()" [buttonData]="{iconUrl:'assets/img/icon/right-arrow.svg', btnClass:'!bg-[#2CC784]'}"></app-rounded-icon-buttom>
    </ng-container>
  </div>
</div>
