import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin.service';
import { TextEditorComponent } from '../text-editor/text-editor.component';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})
export class  OrganizationFormComponent implements OnInit {
  @Input()
  id:any = null
  @Input()
  actions?:any;
  saving:boolean = false
  name = new  FormControl('');
  idF = new FormControl;
  email=new FormControl
  tin =new FormControl
  description =new FormControl('')
  address = new FormControl
  phone = new FormControl
  
  form = this.fb.group({
    id: this.idF,
    email: this.email,
    name: this.name,
    tin: this.tin,
    description: this.description,
    address: this.address,
    phone: this.phone
  });

  
  @ViewChild('editor') editor!: TextEditorComponent;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toast:ToastrService,
    private adminService:AdminService){

  }

  ngOnInit(): void {
    
    if(this.id) this.adminService.getOrganization(this.id).subscribe({next:(data:any) => {
      console.log("My ORg:",data)
      this.form.patchValue({
        email: data.email,
        name: data.name,
        tin: data.tin,
        description: data.description,
        address: data.address,
        phone: data.phone
      })
    },error:(error:any) => {
      this.toast.error(error.error.message, 'ERROR',{toastClass: "custom-toast-error"})
      
    },})
  }
  saveOrg(){
    this.saving = true
    if (!this.form.valid) {
      this.toast.error("Invalid Form", 'ERROR',{toastClass: "custom-toast-error"})
      this.saving = false
      return;
    }

   let data:any = {
      name: this.form.value.name,
      email: this.form.value.email,
      tin: parseInt(this.form.value.tin),
      description:this.editor.getEditor()?.data.get(),// this.form.value.description,
      address: this.form.value.address,
      phone: this.form.value.phone
    };
    
    if(this.id){
      this.adminService.updateOrganizations(this.id,data).subscribe({next:(data) => {

    this.saving = false
        this.toast.success('Organization Updated', 'SUCESS',{toastClass: "custom-toast-success"})
      },error:(error:any) => {
          this.toast.error(error.error.message, 'ERROR',{toastClass: "custom-toast-error"})
          
    this.saving = false
        },})
    }else {
      this.adminService.postOrganizations(data).subscribe({next:data =>{
        //alert(data.id)
        this.toast.success('Organization Added', 'SUCESS',{toastClass: "custom-toast-success"})
        //setTimeout(()=>this.router.navigate(['/config/'+data.id],{queryParams:{tab:1}}),200)
        this.actions?.openProductModal(data.realm)

    this.saving = false
      },error:(error:any) => {

    this.saving = false
          this.toast.error(this.adminService.sovelMessage(error.error), 'ERROR',{toastClass: "custom-toast-error"})

        },})
    }

  }
}
