import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/models/Order';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  isOpen = false;
  
  constructor(){

  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }
  closeSideBar(){
    this.isOpen = false
  }

  ngOnInit(): void {
  }

}
