import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';
import { SideBarComponent } from './side-bar/side-bar.component';
import { Order } from 'src/app/models/Order';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit {
  navMenu: RoundedIconButtonInterface[];
  sideMenu: any[];
  secoundSideMenu: any[];
  orders: Order[] = []
  url?:string;
  route:string = ''
  @ViewChild('sidebar', { static: false }) sidebar!: SideBarComponent;

  ///TEMPORARY
  role =  ["admin","master"].includes(window.location.pathname.split("/")[1])? "admin":"organization";
  
  toggleSidebar() {
    if(this.sidebar)this.sidebar.toggleSidebar();
  }

  toggleAccordion(event: Event) {
    const button = event.target as HTMLElement;
    const content = button.nextElementSibling as HTMLElement;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }
  constructor(private _router: Router, private keycloakService:KeycloakService) {
    
    this.url =_router.url
    this.route = this.url.split("/")[1] || ''
    console.log(this.route)

    this.navMenu = [
      { iconUrl: 'assets/img/icon/bell.svg', isDotVisible: true },
      //{action:()=> this.toggleSidebar(), iconUrl: 'assets/img/icon/cart.svg' },
    ];

    this.sideMenu = [
      { route: '/', name: '',icon:'assets/img/icon/logo-alt.svg',role:['admin','organization']},

      { route: '/', name: 'Dashboard',icon:'assets/img/icon/Frame6.svg',role:['admin','organization']},
      
      { route: '/education/courses', name: 'CMS',icon:'assets/img/icon/Frame7.svg',role:['organization']},

      { route: '/notification', name: 'Notification',icon:'assets/img/icon/Frame8.svg',role:['organization']},

      { route: '/config', name: 'config',icon:'assets/img/icon/Frame9.svg',role:['admin']},
    
      { route: '/reporting', name: 'Reporting',icon:'assets/img/icon/Frame10.svg',role:['admin','organization']},
      
    ];


    this.secoundSideMenu=[
      {
        menu: 'notification',
        title: "Notification",
        itensMenu : [
          { route: "/notification", name: 'Notification List', sub_route: []},
          { route: "/notification/template", name: 'Templates',sub_route: [
            "/notification/template/create"
          ]},
          { route: "/notification/config-smtp", name: 'Configurations',sub_route: [
              "/notification/config-smtp",
              "/notification/config-sms",
              "/notification/config-push",
          ]},
        ]
      },
      {
        menu: 'education',
        title: "Education",
        itensMenu : [
          { route: "/education/courses", name: 'Courses', sub_route: []},
          { route: "/education/modules", name: 'Modules', sub_route: []},
          { route: "/education/lectures", name: 'Lectures', sub_route: []},
          { route: "/education/quizzes", name: 'Quizzes', sub_route: []},
          { route: "/education/quizz-levels", name: 'Quizz Levels', sub_route: []},
        ]
      },
      {
        menu: 'config',
        title: "Organizations",
        itensMenu : [
          { route: "/config", name: 'Organizations', sub_route: []},
        ]
      },
      {
        menu: 'config',
        title: "Products",
        itensMenu : [
          { route: "/config/products", name: 'Products', sub_route: [
            "/config/products/create"
          ]},
          { route: "/config/widgets", name: 'Widgets', sub_route: [
            "/config/widgets/create"
          ]},
        ]

        
      }
    ];
  }
  
  ngOnInit(): void {
  }

  getMenuTile(){
    
      for (var val of this.secoundSideMenu) {
  
          if(val.menu==(this._router.url.split("/")[1])){
            return val.title;
          }
      }
  
    
  }

  getSideMenu(){

    var myMenu = [];

    for (var val of this.secoundSideMenu) {

        if(val.menu==(this._router.url.split("/")[1])){
          //return val.itensMenu;
          myMenu.push(val);
        }
    }

    return myMenu;

  }

  onMenuClick(i: number) {
    this._router.navigate([this.sideMenu[i].route]);
  }

  isEducation(){
    return this._router.url.split("/")[1] == "education"
  }

  isConfig(){
    return this._router.url.split("/")[1] == "config"
  }
  logout(){
    this.keycloakService.logout()
  }

  isNotificationMenu(){
    return this._router.url.split("/")[1] == "notification" || this._router.url.split("/")[1] == "template" || this._router.url.split("/")[1] == "notification-configuration" 
  }

  getCurrentMenu(){
    return this._router.url
  }

}
