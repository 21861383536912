import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Output() btnAction = new EventEmitter<number>();

  onClick(page:number) {
    this.onCurrentChange(page)
    this.btnAction.emit(page);
  }
  Arr = Array
  @Input() totalPage:number = 0
  @Input() size:number = 0
  @Input() current:number = 1


  @Output() currentChange: EventEmitter<number> = new EventEmitter<number>();

  onCurrentChange(page: number): void {
    this.current = page
    this.currentChange.emit(this.current);

  }

}
