import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @ViewChild('modal', { static: false }) modal!: ModalComponent;
  @Output() canceled = new EventEmitter<void>();
  @Output() action = new EventEmitter<void>();
  @Input()
  title?:string
  @Input()
  message?:string

  toggleModal() {
    if(this.modal)this.modal.toggleModal();
  }

  cancel(){
    if(this.modal)this.modal.closeModal();
    this.canceled.emit()
  }

  act(){
    this.action.emit()
  }
}
