<div>
    <table class="w-full">

        <tbody class="">
            <ng-container *ngFor="let p of products">
                <ng-container >
            <tr><td class="h-2"> </td></tr>
            <tr (click)="toggleAccordion($event,p.id)" class="cursor-pointer accordion-item">
    <td class="p-4 rounded-l-2xl bg-[#FFFFFF1A]">
        <img src="assets/img/icon/chevron_right.svg"  width="20px" id="arrow-{{p.id}}" class="rotate-element inline-block pointer-events-none"/>
        {{p.name}}</td>
    <td class="p-4 rounded-r-2xl bg-[#FFFFFF1A] flex justify-end">
        <div>
        
        <label class="switch" (click)="updateProduct(p.id,p)">
            <input type="checkbox" [checked]="p.enabled">
            <span class="slider round"></span>
          </label>

        </div>
    </td>

            </tr>
            <div class="accordion-content scale-element">
                <div (click)="updateWidget(p.id,w)" class="p-4 px-4 hover:border-yv-primary text-[#FFFFFF33] border border-transparent cursor-pointer inline-flex mr-8 my-4 gap-8 items-center justify-between {{w.enabled == true && p.enabled?'!text-white':''}}" *ngFor="let w of getWidgets(p.id)">
                    <label class="checkbox pointer-events-none">
                        <input type="checkbox" [checked]="w.enabled && p.enabled">
                        <span class=""></span>
                      </label>
                    {{w.id}}
                </div>
            </div>
        </ng-container>
        </ng-container>
        </tbody>
    </table>
    <app-loading *ngIf="loading" />

</div>