<div>
        <div class="px-4 z-50" (click)="toggleMenu($event)">
                <img class="pointer-events-none ml-1 " width="14px" src="assets/img/icon/three-dots-vertical.svg" />
        </div>
        
        <div class="absolute z-50" style="display: none;">
                <div class="menu_action absolute z-50 bg-[#FFFFFF] text-sm">
                <div (click)="m.type !='comfirm'? m.action():toggleDialog(m.name,m.message,m.action); afther($event)" class="flex items-center item hover:bg-[#ffffff1a] z-50" 
                        *ngFor="let m of menu">
                        <div class="pointer-events-none">{{m.name}}</div>
                </div>
                <hr class="border-[#ffffff1a]" />
                </div>
                <div (click)="closeMenu($event)" class="fixed top-0 left-0 h-screen bg-[#00000033] w-screen"></div>
        </div>
        
        <app-dialog #dialog [title]="title" [message]="message" (action)="action()"></app-dialog>
</div>