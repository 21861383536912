import { Component, Input, ViewChild } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent {
  
  @ViewChild('dialog', { static: false }) dialog!: DialogComponent;
  @Input() menu:any[] = []
  title:any = null
  message:any = null
  action:any = () =>{}
  toggleMenu(event: Event) {
    const button = event.target as HTMLElement;
    const content = button.nextElementSibling as HTMLElement;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }
  closeMenu(event: Event) {
    const button = event.target as HTMLElement;
    const content = button.parentElement as HTMLElement;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }

  afther(event:Event){
    const button = event.target as HTMLElement;
    const content = button.parentElement?.parentElement as HTMLElement;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }

  toggleDialog(title:string,message:string,action:any){
    this.title = title
    this.action = action
    this.message = message
    if(this.dialog)this.dialog.toggleModal();

  }
}
