import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { ButtonComponent } from '../button/button.component';

@Component({

  standalone: true,
  imports: [CommonModule,RoundedIconButtomComponent,ButtonComponent],
  selector: 'app-back-and-forward',
  templateUrl: './back-and-forward.component.html',
  styleUrls: ['./back-and-forward.component.scss']
})
export class BackAndForwardComponent {
  @Input() current:number = 0;
  @Input() class:string = "";
  @Input() totalSteps: number = 0; // New input property
  @Output() nextClick = new EventEmitter<void>();
  @Output() prevClick = new EventEmitter<void>();
  @Output() endClick = new EventEmitter<void>();
  
  
  next() {
    this.nextClick.emit();
  }

  prev() {
    this.prevClick.emit();
  }
  end() {
    this.endClick.emit();
  }


}
